import { emitError } from 'core/ui/duck';
import nprogress from 'nprogress';
import { all, call, put, select, take } from 'redux-saga/effects';
import { fetchAPI } from 'utils';
import {
    FETCH_VEHICLES,
    FETCH_VEHICLE_ORDERS,
    fetchVehicleOrdersSuccess,
    fetchVehiclesSuccess,
    selectExpandedVehicleId,
    selectFilters,
    selectSort,
    selectVehicleOrdersSort,
    setFetchingVehicleOrders,
    setFetchingVehicles
} from './duck';

export function* fetchVehiclesSaga() {
    while (true) {
        try {
            yield take(FETCH_VEHICLES);

            yield put(setFetchingVehicles(true));

            const sort = yield select(selectSort);
            const filters = yield select(selectFilters);
            let compoundFilters;

            const savedData = localStorage.getItem('drawerCompoundFiltersData');
            if (savedData) {
                try {
                    const parsed = JSON.parse(savedData);
                    console.log(parsed);
                    // parsed.filters.forEach(obj => {
                    //     Object.keys(obj).forEach(key => {
                    //         if (Array.isArray(obj[key]) && obj[key].length === 0) {
                    //             obj[key] = null;
                    //         }
                    //     });
                    // });
                    if (parsed?.filters?.length > 0) {
                        compoundFilters = parsed;
                    }
                } catch (e) {
                    console.warn('Error parsing local filters:', e);
                }
            }

            yield nprogress.start();

            const { clientsVehicles, clientsVehiclesStats } = yield call(
                fetchAPI,
                'GET',
                'vehicles',
                {
                    sort,
                    filters,
                    compoundFilters: compoundFilters?.filters
                },
                null,
                { handleErrorInternally: true }
            );

            yield put(fetchVehiclesSuccess({ vehicles: clientsVehicles, stats: clientsVehiclesStats }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
            yield put(setFetchingVehicles(false));
        }
    }
}

export function* fetchVehicleOrdersSaga() {
    while (true) {
        try {
            yield take(FETCH_VEHICLE_ORDERS);

            yield put(setFetchingVehicleOrders(true));

            const vehicleId = yield select(selectExpandedVehicleId);

            const sort = yield select(selectVehicleOrdersSort);

            yield nprogress.start();

            const { orders, stats } = yield call(
                fetchAPI,
                'GET',
                `orders/vehicle/${vehicleId}`,
                {
                    page: sort.page
                },
                null,
                { handleErrorInternally: true }
            );

            yield put(fetchVehicleOrdersSuccess({ orders, stats }));
        } catch (error) {
            yield put(emitError(error));
        } finally {
            yield nprogress.done();
            yield put(setFetchingVehicleOrders(false));
        }
    }
}

export function* saga() {
    yield all([call(fetchVehiclesSaga), call(fetchVehicleOrdersSaga)]);
}
