import { Input } from 'antd';
import React, { useState } from 'react';

const BankCardInput = () => {
    // A 16-character string with all '*' initially, so the user can fill in the first 4 or last 4.
    // Example: "1234********5678" once partially filled
    const [cardNumber, setCardNumber] = useState('****************');

    // Utility to group the displayed string into blocks of 4 separated by spaces:
    // "1234********5678" => "1234 **** **** 5678"
    const formatWithSpaces = value => {
        // Insert a space after every 4 characters.
        return value.replace(/(.{4})(.{4})(.{4})(.{4})/, '$1 $2 $3 $4');
    };

    // Handle digits typed
    const handleKeyPress = e => {
        // Only allow digits 0-9. If not a digit, prevent the keypress.
        if (!/^\d$/.test(e.key)) {
            e.preventDefault();

            return;
        }

        // Find the first '*' in the current card number
        const indexOfStar = cardNumber.indexOf('*');

        // If there's no '*' left, or if the '*' is in the "middle" range (4..11),
        // prevent typing further
        if (indexOfStar === -1 || (indexOfStar >= 4 && indexOfStar <= 11)) {
            e.preventDefault();

            return;
        }

        // Replace that '*' with the typed digit
        const newCardNumber = cardNumber.substring(0, indexOfStar) + e.key + cardNumber.substring(indexOfStar + 1);

        setCardNumber(newCardNumber);

        // Prevent the typed character from inserting directly
        e.preventDefault();
    };

    // Handle backspace to remove the last typed digit
    const handleKeyDown = e => {
        if (e.key === 'Backspace') {
            e.preventDefault();

            // Search from the end for the last digit that is not '*'
            // and is in the first 4 or the last 4 positions
            for (let i = cardNumber.length - 1; i >= 0; i--) {
                // Allowed positions: indices 0..3 or 12..15
                if ((i < 4 || i >= 12) && /^\d$/.test(cardNumber[i])) {
                    // Replace that digit with '*'
                    const newCardNumber = `${cardNumber.slice(0, i)}*${cardNumber.slice(i + 1)}`;
                    setCardNumber(newCardNumber);
                    break; // stop after reverting the first found digit
                }
            }
        }
    };

    return (
        <Input
            // We show the cardNumber but spaced out for readability
            value={formatWithSpaces(cardNumber)}
            // The main logic for digits is in onKeyPress.
            // The logic for backspace is in onKeyDown.
            onKeyDown={handleKeyDown}
            onKeyPress={handleKeyPress}
            style={{ width: 250 }}
        />
    );
};

export default BankCardInput;
